<script setup>
import { useApp } from "~/composables/useApp";
import { useSettings } from "~/composables/useSettings";

const { settings } = useSettings();

const { bibles, htmlElements } = useApp();

const enableVerseParallel = ref(false);
const enableVerseNumbers = ref(false);
const enableFootnotes = ref(false);

function watchProps() {
  watch(
    () => [enableVerseParallel.value, enableVerseNumbers.value, enableFootnotes.value],
    () => {
      useEvent("settings.update", {
        enableVerseParallel: enableVerseParallel.value,
        enableVerseNumbers: enableVerseNumbers.value,
        enableFootnotes: enableFootnotes.value,
      });
    },
    {
      deep: true,
    }
  );
}

onMounted(() => {
  enableVerseParallel.value = settings.enableVerseParallel;
  enableVerseNumbers.value = settings.enableVerseNumbers;
  enableFootnotes.value = settings.enableFootnotes;

  // watch settings
  watchProps();
});
</script>

<template>
  <div class="px-4">
    <VNavigationHeader
      class="sticky"
      @goback="htmlElements.homeScreensEl.goPrev()"
      label="Settings"
    >
    </VNavigationHeader>

    <!-- switches -->
    <!-- <div class="item mt-4">
      <v-switch v-model="enableVerseParallel"></v-switch>
      <div class="ml-2">{{ transl("Parallel places") }}</div>
    </div> -->
    <!-- <div class="item">
      <v-switch v-model="enableVerseNumbers"></v-switch>
      <div class="ml-2">{{ transl("Numbering of verses") }}</div>
    </div>
    <div class="item">
      <v-switch class="pr-2" v-model="enableFootnotes"></v-switch>
      <div class="ml-2">{{ transl("Footnotes") }}</div>
    </div> -->

    <!-- font -->
    <div class="mt-6">
      <VSettingsFontSize></VSettingsFontSize>
    </div>
    <div class="mt-3"><VSettingsFontFamily></VSettingsFontFamily></div>
    <VSettingsSimplePanel
      class="mt-8"
      :singleBible="bibles.singleBible"
    ></VSettingsSimplePanel>

    <!-- bible preview -->
    <VSettingsBiblePreview :singleBible="bibles.singleBible"></VSettingsBiblePreview>
  </div>
</template>
<style scoped>
.item {
  height: 40px;
  display: flex;
  align-items: center;
  user-select: none;

  font-size: 16px;
  font-weight: 600;
}
.icon-size {
  margin-top: 11px;
}
</style>
